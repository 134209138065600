exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-shop-checkout-success-tsx": () => import("./../../../src/pages/shop/checkout/success.tsx" /* webpackChunkName: "component---src-pages-shop-checkout-success-tsx" */),
  "component---src-pages-shop-checkout-tsx": () => import("./../../../src/pages/shop/checkout.tsx" /* webpackChunkName: "component---src-pages-shop-checkout-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-templates-artwork-tsx": () => import("./../../../src/templates/artwork.tsx" /* webpackChunkName: "component---src-templates-artwork-tsx" */),
  "component---src-templates-price-tsx": () => import("./../../../src/templates/price.tsx" /* webpackChunkName: "component---src-templates-price-tsx" */)
}

